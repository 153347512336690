import "dotenv/config";
import axios from "axios";

axios.defaults.baseURL = "https://localhost:1337/api/";

const PUBLICATIONS_URL = "publications";
const EVENTS_URL = "events";
const PRESS_URL = "presscoverages";
const INSTALLATIONS_URL = "installations";

const getData = (section) => {
  switch (section) {
    case "publications":
      return fetchData(PUBLICATIONS_URL);
    case "events":
      return fetchData(EVENTS_URL);
    case "press":
      return fetchData(PRESS_URL);
    case "installations":
      return fetchData(INSTALLATIONS_URL);
    default:
      console.error(`No section named '${section}' in API.`);
  }
};

const fetchData = (sectionURI) => {
  return axios.get(sectionURI);
};

// const logResponse = (response) => {
//   // console.log(response.data)
//   for (let i = 0; i < response.data.data.length; i++) {
//     let item = response.data.data[i];
//     console.log(`${item.id} : ${item.attributes.title}`);
//   }
// };

// getData("installations");

export default getData;
